<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">{{ $t('aboutUs.aboutUsPageTitle') }}
          </p>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p class="md-subheading bold">
              {{ $t('aboutUs.homepageTitle') }}
            </p>
            <p>
              {{ $t('aboutUs.homepageContent') }}
            </p>
            <p>
              {{ $t('aboutUs.homepageContent2') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('aboutUs.historyTitle') }}
            </p>
            <p>
              {{ $t('aboutUs.historyContent') }}
            </p>
            <p>
              {{ $t('aboutUs.historyContent2') }}
            </p>
            <p>
              {{ $t('aboutUs.historyContent3') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('aboutUs.myFavesTitle') }}
            </p>
            <p>
              {{ $t('aboutUs.myFavesContent') }}
              <router-link :to="{ name: 'home'}">{{ $t('aboutUs.myFavesContentLink') }}</router-link>
              {{ $t('aboutUs.myFavesContent2') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('aboutUs.missedSomethingTitle') }}
            </p>
            <p>
              {{ $t('aboutUs.missedSomethingContent') }}
              <router-link :to="{ name: 'faq'}">{{ $t('aboutUs.missedSomethingLink') }}</router-link>
              {{ $t('aboutUs.missedSomethingContent2') }}
              <router-link :to="{ name: 'contact'}">{{ $t('aboutUs.missedSomethingLink2') }}</router-link>
              {{ $t('aboutUs.missedSomethingContent3') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('aboutUs.joinUsTitle') }}
            </p>
            <p><a href="http://www.facebook.com/getwibki" target="_blank">Facebook</a> | <a
              href="http://www.twitter.com/getwibki" target="_blank">Twitter</a></p>
          </div>
        </div>
      </md-card-content>
    </md-card>

  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>
