<template>
    <amf-page id="home-page" :hasBreadcrumbs="false" :hasSidebar="false">
    <template #content>
      <div class="md-layout md-alignment-center-center">
        <div id="use-top-tabs-card" class="md-layout-item md-size-100">
          <UserTopTabs></UserTopTabs>
        </div>
      </div>
    </template>
  </amf-page>
</template>

<script>
import AmfPage from '@/components/page-templates/AmfPage.vue';
import UserTopTabs from '@/components/common/UserTopTabs.vue';
import loaderMixin from '@/mixins/loaderMixin';

export default {
  name: 'Home',
  components: {
    AmfPage,
    UserTopTabs,
  },
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss" scoped>
#home-page {
  .md-layout {
    flex-wrap: wrap !important;
  }
}
@import '@/assets/scss/layout/home-page.scss';
</style>
